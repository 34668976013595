<template>
  <div class="alert_view" v-if="alertsReady">
    <FilterBar
      :filterData="filterData"
      :listView="listView"
      :alertView="alertView"
      :splashView="splashView"
      @location-selected="locationSelected"
      @alert-level-selected="alertLevelSelected"
      @topic-selected="topicSelected"
      @virus-selected="virusSelected"
      @list-view-selected="listViewSelected"
    />
    <StackChart
      v-if="!splashView"
      :totalWeeks="totalWeeks"
      :weeks="weeks"
      :weekWidth="weekWidth"
      :weekIndex="weekIndex"
      :splashView="splashView"
      :alertView="alertView"
      :listView="listView"
      @index-change="weekChange"
      @alert-selected="alertSelected"
    />
    <Choropleth
      :weeks="weeks"
      :weekIndex="weekIndex"
      :splashView="splashView"
      :alertView="alertView"
      :listView="listView"
      :locationsLookup="locationsLookup"
      :location="filterData.location"
      :lastLocation="filterData.lastLocation"
      :mapView="mapView"
      @location-selected="locationSelected"
      v-if="!listView"
    />
    <Timeline
      :weeks="weeks"
      :totalWeeks="totalWeeks"
      :weekWidth="weekWidth"
      :weekIndex="weekIndex"
      :alertView="alertView"
      :splashView="splashView"
      :listView="listView"
      @index-change="weekChange"
    />
    <Alert :alert="selectedAlert" :alertView="alertView" :extras="extras" />
    <Cards
      :weeks="weeks"
      :weekIndex="weekIndex"
      :totalWeeks="totalWeeks"
      :splashView="splashView"
      :alertView="alertView"
      @alert-selected="alertSelected"
      @alert-deselected="alertDeselected"
      @index-change="weekChange"
    />
    <SplashScreen :splashView="splashView" @hidden="splashHidden" />
  </div>
</template>

<script>
import { groupBy, forEach, uniq } from "lodash";

import FilterBar from "@/components/FilterBar.vue";
import StackChart from "@/components/StackChart.vue";
import Timeline from "@/components/Timeline.vue";
import Cards from "@/components/Cards.vue";
import Alert from "@/components/Alert.vue";
import SplashScreen from "@/components/SplashScreen.vue";
import Choropleth from "@/components/Choropleth.vue";

export default {
  name: "AlertView",
  inject: ["prismic", "dayjs"],
  components: {
    FilterBar,
    SplashScreen,
    StackChart,
    Timeline,
    Alert,
    Cards,
    Choropleth,
  },
  data() {
    return {
      splashView: true, // is the dashboard splash modal visible?
      alertView: false, // are we looking at an individual alert?
      extras: false, // used to hide features that aren't ready or are disabled
      alertsReady: false,
      apiPageNumber: 1,
      weeks: [],
      weekIndex: -1,
      weekWidth: 60,
      calculatingWeeks: false,
      selectedAlert: null,
      listView: true,
      mapView: 0,
      alerts: [],
      locationsLookup: {},
      initUrlState: true,
      loadedWithFilterParam: false,
      addedPopState: false,
      saveFilterState: [],
      filterData: {
        location: "none",
        lastLocation: "none",
        alertLevel: "none",
        topic: "none",
        virus: "none",
        locations: [],
        topics: [],
        alerts: [
          { display: "High", key: "high", value: true },
          { display: "Medium", key: "medium", value: true },
          { display: "Low", key: "low", value: true },
        ],
        viruses: [],
        // viruses: [
        //   { display: "COVID-19", key: "covid-19", value: true },
        //   { display: "HPV", key: "hpv", value: true },
        //   { display: "Flu", key: "flu", value: true },
        //   {
        //     display: "Measles, Mumps, Rubella",
        //     key: "measles-mumps-rubella",
        //     value: true,
        //   },
        //   { display: "Polio", key: "polio", value: true },
        // ],
      },
    };
  },
  methods: {
    weekChange(index) {
      if (this.weeks.length > 0) {
        // set previous week.active to false
        this.weeks[this.weekIndex].active = false;

        // don't set index to week if there are no alerts to display in it
        // instead set to most recent week with displayed alerts
        if (!this.weeks[index].display) {
          for (let i = this.totalWeeks - 1; i >= 0; i--) {
            if (this.weeks[i].display) {
              index = i;
              break;
            }
          }
        }

        // finally set weekIndex and activate selected week
        this.weekIndex = index;
        this.weeks[this.weekIndex].active = true; // set current week.active to true
      }
    },
    splashHidden() {
      if (window.sessionStorage) {
        window.sessionStorage.setItem("splashView", "hide");
      }
      this.splashView = false;
    },
    updateSelectedAlertFlag(enable) {
      if (this.selectedAlert) {
        this.selectedAlert.selected = enable;
      }
    },
    alertSelected(alert) {
      this.updateSelectedAlertFlag(false);
      this.selectedAlert = alert;
      this.selectedAlert.selected = true;
      this.alertView = true;

      //set the unique URL for indivual alerts
      const url = new URL(window.location);
      const searchParams = url.searchParams;
      if (!url.searchParams.has("alert")) {
        searchParams.forEach((value, key) => {
          var filterObj = {};
          filterObj[key] = value;
          this.saveFilterState.push(filterObj);
        });
        this.saveFilterState.forEach((topic) => {
          url.searchParams.delete(Object.keys(topic)[0]);
        });
      }
      url.searchParams.set("alert", alert.slugs[0]);
      window.history.pushState({}, "", url);
    },
    alertDeselected() {
      this.updateSelectedAlertFlag(false);
      this.selectedAlert = null;
      this.alertView = false;

      //remove the unique url slug for alerts
      const url = new URL(window.location);
      url.searchParams.delete("alert");

      //reinsert unique url for topics selected
      if (this.saveFilterState.length > 0) {
        for (var obj of this.saveFilterState) {
          for (var key in obj) {
            url.searchParams.set(key, obj[key]);
          }
        }
      }
      this.saveFilterState = [];

      window.history.pushState({}, "", url);
    },
    locationSelected(location) {
      if (this.filterData.location !== "none") {
        this.filterData.lastLocation = this.filterData.location;
      }
      this.filterData.location = location;
      const url = new URL(window.location);
      if (location !== "none" && this.initUrlState) {
        url.searchParams.set("location", location);
        window.history.pushState({}, "", url);
        this.initUrlState = false;
      } else if (location !== "none" && !this.initUrlState) {
        url.searchParams.set("location", location);
        window.history.replaceState({}, "", url);
      } else {
        url.searchParams.delete("location");
        window.history.replaceState({}, "", url);
        this.saveFilterState = [];
      }
    },
    alertLevelSelected(alertLevel) {
      this.filterData.alertLevel = alertLevel;
      const url = new URL(window.location);
      if (alertLevel !== "none" && this.initUrlState) {
        url.searchParams.set("risk", alertLevel);
        window.history.pushState({}, "", url);
        this.initUrlState = false;
      } else if (alertLevel !== "none" && !this.initUrlState) {
        url.searchParams.set("risk", alertLevel);
        window.history.replaceState({}, "", url);
      } else {
        url.searchParams.delete("risk");
        window.history.replaceState({}, "", url);
        this.saveFilterState = [];
      }
    },
    topicSelected(topic) {
      this.filterData.topic = topic;
      const url = new URL(window.location);
      if (topic !== "none" && this.initUrlState) {
        url.searchParams.set("topic", topic);
        window.history.pushState({}, "", url);
        this.initUrlState = false;
      } else if (topic !== "none" && !this.initUrlState) {
        url.searchParams.set("topic", topic);
        window.history.replaceState({}, "", url);
      } else {
        url.searchParams.delete("topic");
        window.history.replaceState({}, "", url);
        this.saveFilterState = [];
      }
    },
    virusSelected(virus) {
      this.filterData.virus = virus;
      const url = new URL(window.location);
      if (virus !== "none" && this.initUrlState) {
        url.searchParams.set("virus", virus);
        window.history.pushState({}, "", url);
        this.initUrlState = false;
      } else if (virus !== "none" && !this.initUrlState) {
        url.searchParams.set("virus", virus);
        window.history.replaceState({}, "", url);
      } else {
        url.searchParams.delete("virus");
        window.history.replaceState({}, "", url);
        this.saveFilterState = [];
      }
    },
    listViewSelected(isListView) {
      this.listView = isListView;
      this.mapView += 1;
    },
    loadAlerts() {
      const apiEndpoint = "https://projectvctr.cdn.prismic.io/api/v2";
      let prismicClient = this.prismic.client(apiEndpoint);
      prismicClient
        .query(
          this.prismic.Predicates.any("document.type", [
            "misinformation_alert",
          ]),
          {
            pageSize: 100,
            page: this.apiPageNumber,
            fetchLinks: "topic.name, country.name",
          }
        )
        .then((response) => {
          response.results.forEach((alert) => {
            alert.day = this.dayjs(alert.data.post_date);
            alert.timeAgo = alert.day.fromNow();
            alert.selected = false;
          });

          this.alerts = this.alerts.concat(response.results);

          // sort ascending by date
          this.alerts.sort((a, b) => a.day.diff(b.day));

          // console.log("raw alerts", this.alerts);

          // do some cleanup, additons and querying of meta data
          let topics = [];
          let locations = [];
          let vaccines = {};

          forEach(this.alerts, (alert) => {
            alert.display = true;
            let vaccineValues = [];
            let topicValues = [];

            if (alert.data.topics) {
              // console.log('alert topics: ', alert.data.topics);
              alert.data.topics.forEach(({ topic }) => {
                if (topic.uid) {
                  if (
                    topic.tags.length &&
                    topic.tags.includes("vaccine type")
                  ) {
                    vaccines[topic.uid] = topic.data.name;
                    vaccineValues.push(topic.data.name);
                    // topics.push(topic.uid);
                  } else {
                    // topics.push(topic.uid);
                    topics.push(topic.slug);
                    topicValues.push(topic.data.name);
                  }
                }
              });
            }
            if (alert.data.vaccine_types) {
              alert.data.vaccine_types.forEach(({ vaccine }) => {
                if (vaccine && vaccine.uid) {
                  vaccines[vaccine.uid] = vaccine.data.name;
                  vaccineValues.push(vaccine.data.name);
                }
              });
            }

            alert.vaccines = uniq(vaccineValues).sort();
            alert.topics = topicValues;

            let alertMap = {
              "Direct Response": "High",
              "Passive Response": "Medium",
              Ignore: "Low",
            };
            if (alertMap[alert.data.rating]) {
              // console.log(alert.data.rating);
              alert.data.rating = alertMap[alert.data.rating];
            }

            alert.locations = [];

            if (alert.data.locations) {
              alert.data.locations.forEach((location) => {
                if (location.country.uid) {
                  locations.push(location.country.data.name);
                  this.locationsLookup[location.country.data.name] =
                    location.country.uid;
                  alert.locations.push(location.country.data.name);
                }
              });
            }
          });

          this.filterData.topics = [];

          let uniqueTopics = uniq(topics);
          uniqueTopics.sort();
          uniqueTopics.forEach((topic) => {
            this.filterData.topics.push({
              key: topic,
              display: topic.replace(/--/g, " & ").replace(/-/g, " "),
              value: true,
            });
          });

          this.filterData.viruses = Object.keys(vaccines).map((n) => {
            return {
              key: n,
              display: vaccines[n],
              value: true,
            };
          });

          this.filterData.locations = [];

          // console.log('locationsLookup: ', this.locationsLookup);

          let uniqueLocations = uniq(locations);
          uniqueLocations.sort();
          uniqueLocations.forEach((location) => {
            this.filterData.locations.push({
              key: location,
              display: location.replace(/-/g, " "),
              value: true,
            });
          });

          // keep loading pages until we have everything
          if (this.apiPageNumber < response.total_pages) {
            this.apiPageNumber++;
            this.loadAlerts();
          } else {
            this.groupAlertsByWeek();
          }
        });
    },
    groupAlertsByWeek() {
      // let alertsByWeek = [];

      if (this.alerts.length > 0) {
        let now = this.dayjs(); // get today

        let totalWeeksPast = 104;

        for (let i = 0; i < totalWeeksPast; i++) {
          let weeksAlerts = [];

          let weekStart = now.subtract(i * 7, "day");
          let weekEnd = now.subtract((i + 1) * 7, "day");

          forEach(this.alerts, (alert) => {
            if (alert.day.isBetween(weekStart, weekEnd)) {
              weeksAlerts.push(alert);
            }
          });

          // get locations
          let weeksLocations = [];
          forEach(weeksAlerts, (alert) => {
            if (alert.data.locations) {
              alert.data.locations.forEach((location) => {
                let uid = location.country.uid;
                if (uid && uid.length == 2) {
                  weeksLocations.push(uid);
                }
              });
            }
          });
          weeksLocations = uniq(weeksLocations); // remove duplicates, added to alertsByWeekByType below

          // console.log('weeksLocations: ', weeksLocations);

          // group alerts by type e.g. rating: "high", "medium", "low"
          if (weeksAlerts.length > 0) {
            let alertsByWeekByType = groupBy(weeksAlerts, "type");

            forEach(alertsByWeekByType, (array) => {
              array.reverse();
            });

            if (alertsByWeekByType.misinformation_alert) {
              alertsByWeekByType.misinformation_alert = groupBy(
                alertsByWeekByType.misinformation_alert,
                (alert) => {
                  return alert.data.rating;
                }
              );
            } else {
              alertsByWeekByType.misinformation_alert = {};
            }

            alertsByWeekByType.active = false;
            alertsByWeekByType.display = true;

            alertsByWeekByType.dateEnd = weekEnd;
            alertsByWeekByType.dayEnd = weekStart.format("MMM DD YYYY");
            alertsByWeekByType.dayStart = weekEnd.format("MMM DD YYYY");

            alertsByWeekByType.locations = weeksLocations;

            this.weeks.push(alertsByWeekByType);
          }
        }
      }

      this.weeks.reverse();
      this.weekIndex = this.weeks.length - 1;
      this.weeks[this.weekIndex].active = true;

      let lastMonth, thisMonth;
      this.weeks.forEach((week, weekIndex) => {
        thisMonth = week.dateEnd.format("MMM 'YY");
        if (thisMonth != lastMonth) {
          lastMonth = thisMonth;
        } else {
          thisMonth = " ";
        }

        Object.keys(week.misinformation_alert).forEach((key) => {
          week.misinformation_alert[key].forEach((val) => {
            val.weekIndex = weekIndex;
          });
        });
        week.month = thisMonth;
        week.day = week.dateEnd.date();
      });
      this.alertsReady = true;

      // console.log('alertView weeks:', this.weeks);
    },
    loadSingleAlert() {
      const url = new URL(window.location);

      if (url.searchParams.has("alert")) {
        const alertSlug = url.searchParams.get("alert");
        const foundAlert = this.alerts.find((alert) => {
          return alertSlug === alert.slugs[0];
        });
        this.alertSelected(foundAlert);
        this.$nextTick(() => {
          // after initial render
          this.weekChange(foundAlert.weekIndex);
        });
      }
    },
    loadFilters() {
      const url = new URL(window.location);
      const queryParams = (this.urlParams =
        url.search.match(/[a-z\d]+=[a-z\d]+/gi));

      //if vdo first loaded with url params
      if (!this.initUrlState && queryParams) {
        window.history.pushState({}, "", "/");
      }

      if (queryParams && queryParams.length > 0) {
        this.splashHidden();
        if (url.searchParams.has("location")) {
          const location = url.searchParams.get("location");
          this.locationSelected(location);
        }
        if (url.searchParams.has("risk")) {
          const risk = url.searchParams.get("risk");
          this.alertLevelSelected(risk);
        }
        if (url.searchParams.has("virus")) {
          const virus = url.searchParams.get("virus");
          this.virusSelected(virus);
        }
        if (url.searchParams.has("topic")) {
          const topic = url.searchParams.get("topic");
          this.topicSelected(topic);
        }
      } else {
        this.resetAlertView();
      }
    },
    resetAlertView() {
      this.filterData.alertLevel = "none";
      this.filterData.location = "none";
      this.filterData.topic = "none";
      this.filterData.virus = "none";
      this.initUrlState = true;
      this.saveFilterState = [];
    },
    bindPopState() {
      if (this.addedPopState === false) {
        window.addEventListener("popstate", () => {
          if (this.selectedAlert) {
            this.alertDeselected();
          }
          if (!this.initUrlState) {
            this.resetAlertView();
          }
          this.restoreInitState();
        });
        this.addedPopState = true;
      }
    },
    restoreInitState() {
      this.loadFilters();
      // this.resetAlertView()
    },
  },
  computed: {
    totalWeeks: function () {
      return this.weeks.length;
    },
  },
  watch: {
    alertsReady(val) {
      if (val) {
        //TODO consolidate both functions below
        this.loadFilters();
        this.loadSingleAlert();
      }
    },
    filterData: {
      deep: true,
      handler() {
        //console.log("handler");
        forEach(this.alerts, (alert) => {
          let display = true;
          forEach(alert.data.topics, (alertTopic) => {
            forEach(this.filterData.topics, (topic) => {
              if (alertTopic.topic.name == topic.key && !topic.value) {
                display = false;
              }
            });
          });

          if (this.filterData.location !== "none" && display) {
            if (alert.locations.length == 0) {
              display = false;
            } else {
              let inLocation = false;
              forEach(alert.locations, (alertLocation) => {
                if (alertLocation == this.filterData.location) {
                  inLocation = true;
                }
              });
              display = inLocation;
            }
          }

          if (this.filterData.alertLevel !== "none" && display) {
            if (
              alert.data.rating == "Medium" &&
              this.filterData.alertLevel != "medium"
            ) {
              display = false;
            } else if (
              alert.data.rating == "High" &&
              this.filterData.alertLevel != "high"
            ) {
              display = false;
            } else if (
              alert.data.rating == "Low" &&
              this.filterData.alertLevel != "low"
            ) {
              display = false;
            }
          }

          if (this.filterData.topic !== "none" && display) {
            let hasTopic = false;
            forEach(alert.data.topics, (alertTopic) => {
              if (alertTopic.topic.slug === this.filterData.topic) {
                hasTopic = true;
              }
            });
            if (!hasTopic) {
              display = false;
            }
          }

          if (this.filterData.virus !== "none" && display) {
            let hasVirus = false;
            forEach(alert.data.vaccine_types, (vaccine) => {
              if (vaccine.vaccine.uid === this.filterData.virus) {
                hasVirus = true;
              }
            });
            forEach(alert.data.topics, ({ topic }) => {
              if (topic.uid === this.filterData.virus) {
                hasVirus = true;
              }
            });
            if (!hasVirus) {
              display = false;
            }
          }

          alert.display = display;
        });

        // check if filters have hidden all items in a weeks
        // if so hide that week
        this.weeks.forEach((week) => {
          let alerts = week.misinformation_alert;
          let totalDisplayed = 0;

          for (const alertLevel in alerts) {
            alerts[alertLevel].forEach((alert) => {
              if (alert.display) {
                totalDisplayed++;
              }
            });
          }

          if (totalDisplayed > 0) {
            week.display = true;
          } else {
            week.display = false;
          }
        });

        // set the week index back to last item, for scroll considerations
        this.weekChange(this.totalWeeks - 1);
      },
    },
  },
  mounted() {
    this.bindPopState();
  },
  created() {
    this.loadAlerts();
    if (window.sessionStorage) {
      this.splashView = !window.sessionStorage.getItem("splashView");
    }
  },
};
</script>

<style lang="scss"></style>
