<template>
  <TopNav />

  <router-view :key="$route.path" />
</template>

<script>
import TopNav from "./components/TopNav.vue";

export default {
  name: "AlertView",
  components: {
    TopNav,
  },
};
</script>

<style lang="scss">
@import "@/scss/fonts.scss";

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $bg-tertiary rgba(0, 0, 0, 0);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  // background-image: url('https://emojis.slackmojis.com/emojis/images/1597609813/10031/60fps_parrot.gif');
  // background-size: 64px;
}

*::-webkit-scrollbar-thumb {
  background-color: $bg-tertiary;
  border-radius: 3px;
  border: none;
  // background-image: url('https://emojis.slackmojis.com/emojis/images/1597609813/10031/60fps_parrot.gif');
  // background-size: 64px;
  // background-position: center;
}

body {
  background: $bg-primary;
  //overflow: hidden;
  margin: 0;
}

#app {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  //overflow: hidden;
  background: $bg-primary;
  color: $white;
  //font-family: "Mark Pro", sans-serif;
  font-size: 11px;
  line-height: 1.2;
  // letter-spacing: 0.15em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

// .rtl {
//   direction: rtl;
// }

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

a {
  color: $white;
  text-decoration: none;
  border-bottom: 1px solid $white;

  transition: color $uiSpeed, border-bottom $uiSpeed;

  &:hover {
    color: $jade;
    border-bottom: 1px solid $jade;
  }
}

ul {
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 0.5em;

    &:before {
      content: "";
      background: url("~@/images/external-link-alt.svg");
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      display: inline-block;
      width: 1em;
      height: 1em;
      transform: translateY(3px);
      margin-right: 1em;
    }
  }
}
</style>
